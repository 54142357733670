import React from 'react'
import { Helmet } from 'react-helmet'

import './../../scss/main.scss'

import Banner from '../Banner'
import Header from './../Header'
import Footer from './../Footer'

export default function Default({ children }) {
  return (
    <div className="app">
      <Helmet>
        <meta name="author" content="Adrian Gray" />

        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="p:domain_verify" content="82f366f6ebce4f69b600c309ef03eb04" />

        <link rel="apple-touch-icon" sizes="180x180" href="/assets/images/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/images/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/assets/images/favicon/manifest.json" />
        <link rel="mask-icon" href="/assets/images/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div id="fb-root" />
      <Banner />
      <Header />
      {children}
      <Footer />
    </div>
  )
}
