import React from 'react'
import Seo from '../components/Seo'
import Layout from '../components/Layouts/Default'

function Support() {
  return (
    <Layout>
      <div className="contact basic">
        <Seo meta="support" />
        <h1>{`Support`}</h1>
        <p>{`We'll be adding a knowledge base hare as soon as we launch our first app.`}</p>
        <p>
          {`In the meantime, please email any questions to `}
          <a href="mailto:adrian@drlingua.com">{` adrian@drlingua.com`}</a>
        </p>
      </div>
    </Layout>
  )
}

export default Support
