import React, { useEffect, useState } from 'react'
import Link from 'gatsby-link'
import classNames from 'classnames'

export default function Navbar() {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false)
  const [isGamesDropdownOpen, setIsGamesDropdownOpen] = useState(false)

  const handleHamburgerToggleClicked = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setIsHamburgerOpen(!isHamburgerOpen)
  }

  const handleGamesLinkClicked = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setIsHamburgerOpen(!isGamesDropdownOpen)
  }

  useEffect(() => {
    setIsGamesDropdownOpen(isHamburgerOpen)
  }, [isHamburgerOpen])

  /* eslint-disable quote-props */
  const toggleStatus = classNames({
    active: isHamburgerOpen,
  })
  const navStatus = classNames('nav-list', {
    displayBlock: isHamburgerOpen,
  })

  const displayGamesDropdown = classNames('nav-dropdown', {
    displayNone: !isGamesDropdownOpen,
    displayBlock: isGamesDropdownOpen,
  })
  /* eslint-enable quote-props */

  return (
    <nav className="navbar">
      <div className="nav-mobile">
        <Link to="#" id="nav-toggle" className={toggleStatus} onClick={handleHamburgerToggleClicked}>
          <span />
        </Link>
      </div>
      <ul className={navStatus}>
        <li>
          <Link to="/">{'Home'}</Link>
        </li>
        <li>
          <span className="nav-element" onClick={handleGamesLinkClicked}>
            {'Games'}
          </span>
          <ul className={displayGamesDropdown}>
            <li>
              <Link to="/japanese/games/kana-bento/">{'Kana Bento'}</Link>
            </li>
            <li>
              <Link to="/japanese/games/kana-grid/">{'Kana Grid'}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/blog/">{'Blog'}</Link>
        </li>
      </ul>
    </nav>
  )
}
