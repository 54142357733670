import React from 'react'
import Link from 'gatsby-link'

import Navbar from './Navbar'

function Header () {
  return (
    <header>
      <Link to='/'>
        <img src='/assets/images/dr-lingua-icon.jpg' alt='Dr Lingua logo' />
        <h4>{'Dr Lingua'}</h4>
      </Link>
      <Navbar />
    </header>
  )
}

export default Header
